<template>
  <main>
    <section
      v-if="is_first_view"
      class="skintypeCheck"
    >
      <div class="skintypeCheck_container">
        <div>美容皮膚科医監修</div>
        <h1>肌質診断</h1>
        <div class="skintypeCheck_explanation">
          あなたの現在の肌状態やお悩みなど、質問に回答していくことで、肌質を分析いたします。
          自分の肌質を理解し、その肌質にあった化粧品を見つけるお手伝いをいたします。
        </div>
        <a
          class="skintypeCheck_btn"
          @click="startFlow"
        >
          START
        </a>
        <div class="supervisor">
          <h2>監修</h2>
          <img
            :src="
              IMAGE_ROOT_URL
                + '/face-icon/hamano.png'
            "
          >
          <h3>濱野英明</h3>
          <div class="supervisor_profile">
            <p>テティス横濱美容皮膚科&nbsp;&nbsp;院長</p>
            <p>日本スキンケア協会・皮膚科顧問</p>
            <p>2015ミス・ユニバース&nbsp;ビューティーキャンプ講師</p>
          </div>
          <div class="profile_btn_container">
            <router-link
              :to="{name: 'Concierges', params: {'incomingGenre': 'skintype'}}"
              class="profile_btn"
            >
              プロフィールを見る
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <question
      v-if="!is_first_view"
      series="skin-type-diag"
    />
  </main>
</template>
<script>
import Question from '../../components/flow/Question.vue'

export default {
  name: 'FlowSkinTypeDiag',
  components: {
    Question
  },
  data(){
    return {
      is_first_view: true,
      IMAGE_ROOT_URL: process.env.VUE_APP_IMAGE_ROOT
    }
  },
  methods:{
    startFlow(){
      this.is_first_view=false;
    }
  }
}
</script>
<style scoped>
.skintypeCheck {
    background-color: #ffffff;
    text-align: center;
    font-size: 14px;
    padding: 0 40px;
    color: #777777;
}
.skintypeCheck_container {
    padding: 45px 0;
}
.skintypeCheck_explanation {
    padding-bottom: 57px;
    text-align: left;
}
.skintypeCheck_btn {
    font-size: 24px;
    font-weight: bold;
    display: block;
    width: 90%;
    margin: 0 auto;
    border-radius: 100vh;
    padding: 15px 0;
    background-color: #F29F8F;
    color: #ffffff;
}
.supervisor {
    background-color: #ffffff;
    padding: 100px 0 61px 0;
}
h1 {
    font-size: 24px;
    font-weight: bold;
    color: #F29F8F;
    padding: 12px 0 28px 0;
}
h2 {
    font-size: 22px;
    font-weight: bold;
    text-align: left;
}
h3 {
    font-size: 20px;
    text-align: left;
    color: #222222;
}
.profile_btn{
    color: #707070;
    background-color: #FADAD2;
    padding: 11px 21px;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
}
.supervisor img {
    width: 100%;
    object-fit: cover;
    border: #F29F8F;
    padding: 10px 0;
}
.supervisor_profile {
    padding: 10px 0 20px 0;
}
.skintypeCheck p {
    text-align: left;
}
</style>
